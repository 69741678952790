@import '../../style/App.scss';
#about {
    padding: 20px 0;
}

.para {
    margin-left: 50px;
    font-size: 1.5rem;
}

.location-link {
    color: #000;
    text-decoration: none;
}

.location-link:hover {
    color: #cda046;
}

@media screen and (max-width: 990px) {
    #about {
        text-align: center;
    }
    .para {
        margin-left: 0;
    }
}